import { IllustratedList, IllustratedListItems } from 'components/IllustratedList'
import { Link } from 'components/Link'
import { Stack } from 'components/Stack'
import { Text } from 'components/Text'
import { Traits } from 'features/components/Traits/Traits'
import { useTranslation } from 'hooks/useTranslation'
import Image from 'next/image'
import {
  MealPlanCTA,
  MealPlanImageContainer,
  MealPlanSection,
  MealPlanSectionContent,
  MealPlanSubtitle,
} from './TraitsSection.styles'

import mealPlanImg from '../../../../../public/assets/meal-plan.png'
import stepTailor from '../../../../../public/assets/illustration-tailor.svg'
import stepShip from '../../../../../public/assets/illustration-ship.svg'
import stepFeed from '../../../../../public/assets/illustration-feed.svg'

export const TraitsSection = () => {
  const { t } = useTranslation()

  const STEPS: IllustratedListItems[] = [
    {
      image: stepTailor,
      title: t('home:mealPlan.step1.title'),
      description: t('home:mealPlan.step1.description'),
      loadType: 'lazy',
    },
    {
      image: stepShip,
      title: t('home:mealPlan.step2.title'),
      description: t('home:mealPlan.step2.description'),
      loadType: 'lazy',
    },
    {
      image: stepFeed,
      title: t('home:mealPlan.step3.title'),
      description: t('home:mealPlan.step3.description'),
      loadType: 'lazy',
    },
  ]

  return (
    <Stack gap={{ '@initial': '6', '@bp1': '10' }} direction="column">
      <Traits />
      <MealPlanSection>
        <MealPlanSectionContent>
          <MealPlanImageContainer>
            <Image src={mealPlanImg} alt={t('home:mealPlan.image')} loading="lazy" />
          </MealPlanImageContainer>

          <Stack gap={{ '@initial': 6, '@bp1': 12 }} direction="column" css={{ width: '100%' }}>
            <Stack gap="2" direction="column" alignItems="center">
              <Text as="h2" size="h2" weight="medium" align="center" css={{ maxWidth: '512px' }}>
                {t('home:mealPlan.title')}
              </Text>

              <Stack gap={{ '@initial': 6, '@bp1': 12 }} direction="column" css={{ width: '100%' }}>
                <MealPlanSubtitle as="p" size="headline" align="center" css={{ margin: 'auto' }}>
                  {t('home:mealPlan.subtitle')}
                </MealPlanSubtitle>

                <Stack justifyContent="center">
                  <IllustratedList items={STEPS} />
                </Stack>
              </Stack>
            </Stack>

            <Link href="/meal-plan" passHref>
              <MealPlanCTA>{'Create your Meal Plan'}</MealPlanCTA>
            </Link>
          </Stack>
        </MealPlanSectionContent>
      </MealPlanSection>
    </Stack>
  )
}
