import { buttonReset } from 'components/Button/Button.styles'
import { Stack } from 'components/Stack'
import { Text } from 'components/Text'
import { styled } from 'stitches.config'

export const FamilyTitleContainer = styled(Stack, {
  margin: 'auto',

  '&': {
    [`${Text}:first-child`]: {
      maxWidth: '400px',
    },
  },
})

export const CollapseTrigger = styled('button', {
  ...buttonReset,
  display: 'block',
  margin: 'auto',
  color: '$primary-500',

  '@bp2': {
    display: 'none',
  },
})

export const IsotypeContainer = styled('div', {
  display: 'none',

  '@bp3': {
    display: 'block',
    size: '$15',
    position: 'absolute',
    top: '-$5',
    right: '150px',
  },
})

export const FamilyContentContainer = styled('div', {
  position: 'relative',

  '& > img': {
    display: 'none',
  },

  '@bp2': {
    px: '$14',
  },

  variants: {
    isCollapsed: {
      true: {
        display: 'none',

        '@bp2': {
          display: 'flex',
        },
      },
    },
  },
})

export const FamilyImageContainer = styled('div', {
  marginBottom: '$4',
  width: '327px',

  '@bp1': {
    width: '450px',
  },

  '@bp2': {
    width: '584px',
  },

  '@bp3': {
    marginBottom: '0',
  },
})

export const Images = styled('div', {
  flex: 1,
})

export const FamilySectionContent = styled(Stack, {
  maxWidth: '$max',
  alignSelf: 'center',
  gap: '$3',
  color: '$primary-800',
  margin: 'auto',
  px: '$3',
  paddingTop: '$6',

  '@bp2': {
    paddingTop: '$10',
  },
})

export const FamilySectionRoot = styled('section', {
  marginBottom: '$6',

  '@bp2': {
    backgroundImage:
      'url(assets/pattern-home-desktop-05.png), url(assets/pattern-home-desktop-06.png)',
    backgroundSize: '150px, 60px',
    backgroundRepeat: 'no-repeat',
    paddingBottom: '160px',
    backgroundPosition: 'left -30%, right 130%',
    marginBottom: '-$10',
  },

  variants: {
    alternate: {
      true: {
        backgroundImage: 'none',
      },
    },
  },
})
