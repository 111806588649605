import { Button } from 'components/Button'
import { Text } from 'components/Text'
import { styled } from 'stitches.config'

export const MealPlanImageContainer = styled('div', {
  display: 'none',

  '@bp3': {
    transform: 'translateY(-25%)',
    display: 'block',
    position: 'absolute',
    top: '-$10',
    left: '$6',
    width: '300px',
  },
})

export const MealPlanSubtitle = styled(Text, {
  maxWidth: '100%',
  '@bp2': {
    maxWidth: '45%',
  },
})

export const MealPlanSectionContent = styled('div', {
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  maxWidth: '$max',
  margin: 'auto',
  px: '$3',
})

export const MealPlanCTA = styled(Button, {
  alignSelf: 'center',
  display: 'none',

  '@bp2': {
    display: 'inline-flex',
  },
})

export const MealPlanSection = styled('section', {
  color: '$primary-800',
  backgroundImage: 'url(assets/pattern-home-desktop-03.png)',
  backgroundSize: '120px',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'right center',

  '@bp2': {
    backgroundSize: '220px',
    px: '$9',
  },
})
