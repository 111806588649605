import React, { ComponentProps, useState } from 'react'
import useTranslation from 'next-translate/useTranslation'
import { Text } from 'components/Text'
import { Stack } from 'components/Stack'
import Image from 'next/image'
import {
  CollapseTrigger,
  FamilyContentContainer,
  IsotypeContainer,
  FamilyImageContainer,
  FamilySectionContent,
  FamilySectionRoot,
  FamilyTitleContainer,
  Images,
} from './FamilySection.styles'
import alyssa from '../../../../../public/assets/alissa-zalneraitis.png'
import isotype from '../../../../../public/assets/isotype-line.svg'

type FamilySectionOwnProps = ComponentProps<typeof FamilySectionRoot>
export type FamilySectionProps = FamilySectionOwnProps & {}

export const FamilySection = ({ alternate }: FamilySectionProps) => {
  const { t } = useTranslation()
  const [isCollapsed, setIsCollapsed] = useState(true)

  return (
    <FamilySectionRoot alternate={alternate}>
      <FamilySectionContent direction="column" gap={{ '@initial': '0', '@bp2': '1' }}>
        <FamilyTitleContainer>
          <Stack direction="column" alignItems="center" gap={{ '@initial': '2', '@bp2': '3' }}>
            <Text size={{ '@initial': 'h3', '@bp2': 'h1' }} weight="medium" as="h2" align="center">
              {t('home:family.title')}
            </Text>
          </Stack>
        </FamilyTitleContainer>
        <FamilyContentContainer isCollapsed={false}>
          <Text size="headline" align={{ '@initial': 'center', '@bp2': 'left' }} css={{ flex: 1 }}>
            {t('home:family.subtitle')}
          </Text>
        </FamilyContentContainer>

        <FamilyContentContainer isCollapsed={isCollapsed}>
          <Stack
            direction={{ '@initial': 'column', '@bp3': 'row' }}
            gap={{ '@initial': '1', '@bp1': '3', '@bp2': '11' }}
            alignItems="center"
          >
            <Text
              size="headline"
              align={{ '@initial': 'center', '@bp2': 'left' }}
              css={{ flex: 1 }}
            >
              <p>{t('home:family.content1')}</p>
              <br />
              <p>{t('home:family.content2')}</p>
            </Text>
            <Images>
              <IsotypeContainer>
                <Image src={isotype} alt="" loading="lazy" />
              </IsotypeContainer>
              <FamilyImageContainer>
                <Stack alignItems="center" direction="column">
                  <Image src={alyssa} alt={t('home:family.image')} loading="lazy" />
                  <Text size="headline" weight="medium">
                    {t('home:family.alissa')}
                  </Text>
                  <Text>{t('home:family.original-founder')}</Text>
                </Stack>
              </FamilyImageContainer>
            </Images>
          </Stack>
        </FamilyContentContainer>

        <CollapseTrigger onClick={() => setIsCollapsed(!isCollapsed)}>
          <Text as="p" size="headline" weight="medium" align="center">
            {isCollapsed ? t('home:family.show-more') : t('home:family.show-less')}
          </Text>
        </CollapseTrigger>
      </FamilySectionContent>
    </FamilySectionRoot>
  )
}

FamilySection.displayName = 'FamilySection'
