import { Text } from 'components/Text'
import { useTranslation } from 'hooks/useTranslation'
import Image from 'next/image'
import { NutritionClaim, NutritionIsotypeContainer } from './NutritionClaimSection.styles'

import isotype from '../../../../../public/assets/isotype-fill.svg'

export const NutritionClaimSection = () => {
  const { t } = useTranslation()

  return (
    <NutritionClaim>
      <NutritionIsotypeContainer>
        <Image src={isotype} alt="" loading="lazy" />
      </NutritionIsotypeContainer>
      <Text size={{ '@initial': 'headline', '@bp2': 'h3' }} as="h3" weight="medium" align="center">
        {t('home:nutrition-claim')}
      </Text>
    </NutritionClaim>
  )
}
