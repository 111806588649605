import { styled } from 'stitches.config'

export const DogFoodPerkRoot = styled('div', {
  display: 'inline-flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '$2',

  '@bp2': {
    gap: '$3',
    flexDirection: 'row',

    '&:not(:last-of-type)': {
      paddingBottom: '$6',
      marginBottom: '$6',
      borderBottom: '1px solid red',
    },
  },

  '&:not(:last-of-type)': {
    paddingBottom: '$3',
    marginBottom: '$3',
    borderBottom: '1px solid red',
  },
})

export const IconCircle = styled('div', {
  size: '$14',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '$secondary-100',
  border: '2px solid $primary-800',
  color: '$primary-800',
  borderRadius: '$round',

  flexShrink: '0',
})

export const TextContent = styled('div', {
  maxWidth: '412px',
})
