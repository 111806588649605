import { FC, ReactNode } from 'react'
import Image from 'next/image'
import Trans from 'next-translate/Trans'
import { Icon } from 'components/Icon'
import { Link } from 'components/Link'
import { Stack } from 'components/Stack'
import { Text } from 'components/Text'
import { useTranslation } from 'hooks/useTranslation'
import { Fact, FactContentContainer, FactImageContainer, ProteinsList } from './FactSection.styles'

import benefits2 from '../../../../../public/assets/benefits-2.png'

export const FactSection = () => {
  const { t } = useTranslation()

  return (
    <section>
      <Fact>
        <FactImageContainer>
          <Image src={benefits2} alt="" loading="lazy" />
        </FactImageContainer>

        <FactContentContainer>
          <Stack direction="column" gap="7">
            <Text size="headline" color="primary-800" weight="medium">
              <Trans
                i18nKey="home:proteins.title"
                components={{
                  red: <MealPlanLink />,
                }}
              />
            </Text>

            <ProteinsList>
              <li>
                <Stack alignItems="center">
                  <Icon name="cow" color="primary-500" size="8" />
                  <Text size="headline" as="span" color="primary-800" weight="medium">
                    {t('home:proteins.beef')}
                  </Text>
                </Stack>
              </li>
              <li>
                <Stack alignItems="center">
                  <Icon name="turkey" color="primary-500" size="8" />
                  <Text size="headline" as="span" color="primary-800" weight="medium">
                    {t('home:proteins.turkey')}
                  </Text>
                </Stack>
              </li>
              <li>
                <Stack alignItems="center">
                  <Icon name="chicken" color="primary-500" size="8" />
                  <Text size="headline" as="span" color="primary-800" weight="medium">
                    {t('home:proteins.chicken')}
                  </Text>
                </Stack>
              </li>
              <li>
                <Stack alignItems="center">
                  <Icon name="deer" color="primary-500" size="8" />
                  <Text size="headline" as="span" color="primary-800" weight="medium">
                    {t('home:proteins.venison')}
                  </Text>
                </Stack>
              </li>
              <li>
                <Stack alignItems="center">
                  <Icon name="duck" color="primary-500" size="8" />
                  <Text size="headline" as="span" color="primary-800" weight="medium">
                    {t('home:proteins.duck')}
                  </Text>
                </Stack>
              </li>
              <li>
                <Stack alignItems="center">
                  <Icon name="lamb" color="primary-500" size="8" />
                  <Text size="headline" as="span" color="primary-800" weight="medium">
                    {t('home:proteins.lamb')}
                  </Text>
                </Stack>
              </li>
            </ProteinsList>
          </Stack>
        </FactContentContainer>
      </Fact>
    </section>
  )
}

const MealPlanLink: FC<{ children?: ReactNode }> = ({ children }) => (
  <Link href="/meal-plan" passHref>
    <Text as="a" size="headline" weight="medium" color="primary-500">
      {children}
    </Text>
  </Link>
)
