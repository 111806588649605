import { styled } from 'stitches.config'

export const FactImageContainer = styled('div', {
  display: 'flex',
  flex: 1,
})

export const FactContentContainer = styled('div', {
  display: 'flex',
  flex: 1,
})

export const Fact = styled('div', {
  maxWidth: '$max',
  margin: 'auto',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '$5',
  px: '$3',
  paddingBottom: '$6',

  '@bp2': {
    px: '$9',
    flexDirection: 'row-reverse',
    gap: '$9',
    paddingBottom: 0,
  },
})

export const ProteinsList = styled('ul', {
  margin: 'auto',
  listStyle: 'none',
  display: 'flex',
  alignItems: 'center',
  justifycontent: 'space-between',
  flexWrap: 'wrap',

  '& > li': {
    flex: '50%',
  },

  '& > li:not(:first-child), & > li:not(:nth-child(2))': {
    marginTop: '$3',
  },

  '@bp2': {
    '& > li:not(:first-child), & > li:not(:nth-child(2))': {
      marginTop: '$2',
    },
  },
})
