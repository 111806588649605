import Image from 'next/image'
import { Text } from 'components/Text'
import { Stack } from 'components/Stack'
import { BalanceSectionContent, BalanceSectionRoot, ImageContainer } from './BalanceSection.styles'

import * as balanceDog from '../../../../../public/assets/home/balance-dog.png'
import { useTranslation } from 'hooks/useTranslation'
import Trans from 'next-translate/Trans'

export const BalanceSection = () => {
  const { t } = useTranslation()
  return (
    <BalanceSectionRoot>
      <BalanceSectionContent>
        <Stack direction="column" gap="3" css={{ order: 1 }}>
          <Text as="h2" size="h1" weight="bold">
            {t('home:balance.title')}
          </Text>
          <Stack direction="column" gap="4">
            <Text size="headline" weight="bold">
              {t('home:balance.subtitle')}
            </Text>
            <Text size="headline">
              <Trans
                i18nKey="home:balance.description"
                components={{ bold: <strong />, br: <br /> }}
              />
            </Text>
          </Stack>
        </Stack>
        <ImageContainer>
          <Image src={balanceDog} alt="dog portrait photo" loading="lazy" />
        </ImageContainer>
      </BalanceSectionContent>
    </BalanceSectionRoot>
  )
}
