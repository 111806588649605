import { styled } from 'stitches.config'

export const SecondaryCTA = styled('p', {
  color: '$secondary-100',

  '& span': {
    textDecoration: 'none',
  },

  '& a': {
    fontSize: '$headline',
    fontWeight: '$medium',
    lineHeight: '$headline',
  },
})

export const ScrollTopButton = styled('button', {
  cursor: 'pointer',
  position: 'relative',
  background: 'transparent',
  border: 'none',

  '& svg': {
    size: '$7',
    color: '$secondary-500',
  },

  '@bp3': {
    position: 'absolute',
    top: '$6',
    right: '$6',

    '& svg': {
      size: '$8',
    },
  },
})

export const EndingRoot = styled('div', {
  position: 'relative',
  width: '100%',
  backgroundColor: '$primary-800',
  color: '$secondary-500',
  textAlign: 'center',
  padding: '$6 $3 $3',

  '@bp1': {
    padding: '$8 $5 $6',
    borderRadius: '75px 75px 0px 0px',
  },
})
