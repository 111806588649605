import { Footer } from 'components/Footer'
import { Header } from 'components/Header'
import { Layout } from 'components/Layout'
import { Stack } from 'components/Stack'
import { VerticalSeparator } from 'components/VerticalSeparator'
import { Benefits } from 'features/components/Benefits'
import { BalanceSection } from 'features/Home/components/BalanceSection'
import { ClaimSection } from 'features/Home/components/ClaimSection'
import { DogFoodSection } from 'features/Home/components/DogFoodSection'
import { Ending } from 'features/Home/components/Ending'
import { FactSection } from 'features/Home/components/FactSection'
import { Hero } from 'features/Home/components/Hero'
import { NutritionClaimSection } from 'features/Home/components/NutritionClaimSection'
import { PropertiesSection } from 'features/Home/components/PropertiesSection'
import { ReviewItem, ReviewsSection } from 'features/Home/components/ReviewsSection'
import { TraitsSection } from 'features/Home/components/TraitsSection'
import { HomeRoot, Wrapper } from 'features/Home/screens/Home/Home.styles'
import { useRouter } from 'hooks/useRouter'
import useTranslation from 'next-translate/useTranslation'
import { useEffect, useState } from 'react'
import { isElementVisible } from 'utils/dom'
import { FamilySection } from '../../components/FamilySection'

export const HomeAlternate = () => {
  const header = <Header snapToTop />
  const { t } = useTranslation()
  const [hideCTA, setHideCTA] = useState(false)
  const { query } = useRouter()

  useEffect(() => {
    document.addEventListener('scroll', (e) => {
      if (isElementVisible('a.cta-footer')) {
        setHideCTA(true)
      } else {
        setHideCTA(false)
      }
    })
  }, [])

  const REVIEWS: ReviewItem[] = [
    {
      name: t('home:reviews.1.name'),
      message: t('home:reviews.1.message'),
    },
    {
      name: t('home:reviews.2.name'),
      message: t('home:reviews.2.message'),
    },
    {
      name: t('home:reviews.3.name'),
      message: t('home:reviews.3.message'),
    },
    {
      name: t('home:reviews.4.name'),
      message: t('home:reviews.4.message'),
    },
  ]

  return (
    <HomeRoot>
      <Layout header={header} hideCTA={hideCTA}>
        <Wrapper>
          <Stack direction="column" gap={{ '@initial': '5', '@bp2': '12' }}>
            <Hero />

            <Benefits />

            <TraitsSection />

            <Stack direction="column" alignItems="center" gap={{ '@initial': '1', '@bp2': '3' }}>
              <ClaimSection />

              <div>
                <VerticalSeparator color="primary-800" size="11" />
              </div>

              <Stack gap="2" direction="column">
                <PropertiesSection />

                <FactSection />
              </Stack>
            </Stack>

            <div>
              <NutritionClaimSection />

              <ReviewsSection
                reviews={REVIEWS}
                title={t('home:reviews.title')}
                cta={t('home:reviews.cta')}
              />
            </div>
          </Stack>

          <FamilySection alternate />

          <DogFoodSection />

          <BalanceSection />

          <Ending />

          <Footer />
        </Wrapper>
      </Layout>
    </HomeRoot>
  )
}

HomeAlternate.displayName = 'HomeAlternate'
