import { Icon } from 'components/Icon'
import { IconName } from 'components/Icon/iconNames'
import { Stack } from 'components/Stack'
import { Text } from 'components/Text'
import { useTranslation } from 'hooks/useTranslation'
import { TranslationKeys } from 'locales/translationKeys'
import Trans from 'next-translate/Trans'
import { DogFoodPerkRoot, IconCircle, TextContent } from './DogFoodPerk.styles'

type DogFoodPerkProps = {
  icon: IconName
  title: TranslationKeys
  body: TranslationKeys
}

export const DogFoodPerk = ({ icon, title, body }: DogFoodPerkProps) => {
  const { t } = useTranslation()

  return (
    <DogFoodPerkRoot>
      <IconCircle>
        <Icon name={icon} size="7" />
      </IconCircle>

      <TextContent>
        <Stack direction="column">
          <Text as="h3" size="h5" weight="medium">
            {t(title)}
          </Text>
          <Text as="p">
            <Trans i18nKey={body} components={{ bold: <strong /> }} />
          </Text>
        </Stack>
      </TextContent>
    </DogFoodPerkRoot>
  )
}
