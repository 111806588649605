import { styled } from 'stitches.config'
import { DogFoodPerkRoot } from './components/DogFoodPerk/DogFoodPerk.styles'

export const DogFoodSectionRoot = styled('section', {
  backgroundColor: '$secondary-100',
  color: '$primary-800',
})

export const DogFoodSectionContent = styled('div', {
  margin: 'auto',
  maxWidth: '$max',
  px: '$3',

  '@bp2': {
    px: '$10',
  },

  '@bp3': {
    px: '$15',
  },
})

export const Card = styled('div', {
  borderTopLeftRadius: '75px',
  borderTopRightRadius: '75px',
  backgroundColor: '$secondary-500',
  py: '$10',

  borderBottom: '9px solid transparent',
  borderTop: 0,
  borderImage: 'url(/assets/pattern-footer-border-mobile.svg) 30',
})

export const SectionTitle = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$1',
  alignItems: 'center',
  justifyContent: 'flex-start',
  marginBottom: '$4',

  '@bp2': {
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    gap: '$8',
  },
})

export const Illustration = styled('figure', {
  flexShrink: 0,
  display: 'flex',
  maxWidth: '399px',
  maxHeight: '399px',

  '@bp3': {
    width: '499px',
    height: '499px',
    maxWidth: 'initial',
    maxHeight: 'initial',
  },
})

export const TextConainer = styled('div', {
  maxWidth: '50%',
})

export const SectionContent = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  gap: 0,

  '@bp2': {
    flexDirection: 'row',
    justifyContent: 'center',
    gap: '$7',
  },
})

export const Column = styled('div', {
  py: '$4',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'center',

  [`&:not(:last-of-type) ${DogFoodPerkRoot}:last-of-type`]: {
    paddingBottom: '$3',
    borderBottom: '1px solid red',
  },
  [`&:last-of-type`]: {
    paddingTop: 0,
  },

  '@bp2': {
    [`&:not(:last-of-type) ${DogFoodPerkRoot}:last-of-type`]: {
      paddingBottom: '0',
      marginBottom: '0',
      borderBottom: '0',
    },
  },
})

export const Separator = styled('div', {
  borderLeft: '4px dotted $primary-500',
  display: 'none',

  '@bp2': {
    display: 'block',
  },
})
