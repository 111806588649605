import Image from 'next/image'
import {
  Card,
  Column,
  DogFoodSectionContent,
  DogFoodSectionRoot,
  Illustration,
  SectionContent,
  SectionTitle,
  Separator,
  TextConainer,
} from './DogFoodSection.styles'

import * as dogFoodIllustration from '../../../../../public/assets/home/dog-food-illustration.png'
import { Stack } from 'components/Stack'
import { Text } from 'components/Text'
import { DogFoodPerk } from './components/DogFoodPerk'
import { VerticalSeparator } from 'components/VerticalSeparator'
import { useTranslation } from 'hooks/useTranslation'

export const DogFoodSection = () => {
  const { t } = useTranslation()

  return (
    <DogFoodSectionRoot>
      <Card>
        <DogFoodSectionContent>
          <SectionTitle>
            <Illustration>
              <Image src={dogFoodIllustration} alt="" loading="lazy" />
            </Illustration>

            <Stack direction="column" gap={{ '@bp2': '3', '@initial': '2' }}>
              <Text as="h2" size="h1" weight="bold">
                {t('home:dog-food.title')}
              </Text>
              <Stack direction="column" gap={{ '@bp2': '4', '@initial': '2' }}>
                <Text size="headline" weight="bold">
                  {t('home:dog-food.subtitle')}
                </Text>
                <Text as="p" size="headline">
                  {t('home:dog-food.description')}
                </Text>
              </Stack>
            </Stack>
          </SectionTitle>

          <SectionContent>
            <Column>
              <DogFoodPerk
                icon="circle-layer"
                title={'home:dog-food.perks.variety.title'}
                body={'home:dog-food.perks.variety.body'}
              />
              <DogFoodPerk
                icon="badge"
                title={'home:dog-food.perks.quality.title'}
                body={'home:dog-food.perks.quality.body'}
              />
              <DogFoodPerk
                icon="dog-nose"
                title={'home:dog-food.perks.personalization.title'}
                body={'home:dog-food.perks.personalization.body'}
              />
            </Column>
            <Separator />
            <Column>
              <DogFoodPerk
                icon="shield"
                title={'home:dog-food.perks.safety.title'}
                body={'home:dog-food.perks.safety.body'}
              />
              <DogFoodPerk
                icon="house"
                title={'home:dog-food.perks.convenience.title'}
                body={'home:dog-food.perks.convenience.body'}
              />
            </Column>
          </SectionContent>
        </DogFoodSectionContent>
      </Card>
    </DogFoodSectionRoot>
  )
}
