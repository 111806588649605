import { Text } from 'components/Text'
import { styled } from 'stitches.config'

export const NutritionIsotypeContainer = styled('div', {
  position: 'absolute',
  transform: 'translate(-50%, -50%)',
  top: 0,
  left: '50%',
  size: '$11',

  '@bp2': {
    size: '112px',
  },
})

export const NutritionClaim = styled('section', {
  position: 'relative',
  textAlign: 'center',
  backgroundColor: '$secondary-500',
  padding: '$9 $3 $6',
  color: '$primary-800',

  '@bp2': {
    paddingTop: '$12',
    paddingBottom: '$10',

    '&': {
      [`${Text}`]: {
        maxWidth: '1028px',
      },
    },
  },
})
