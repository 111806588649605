import { styled } from 'stitches.config'

export const BalanceSectionRoot = styled('section', {
  backgroundColor: '$accent-account-submenu',
  color: '$primary-800',
  py: '$12',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: '$8',

  'span, p, h2': {
    textAlign: 'center',
  },

  '@bp2': {
    flexDirection: 'row',

    'span, p, h2': {
      textAlign: 'initial',
    },
  },
})

export const BalanceSectionContent = styled('div', {
  maxWidth: '$max',
  margin: 'auto',
  px: '$3',

  '@bp2': {
    px: '$10',
  },

  '@bp3': {
    px: '$15',
  },
})

export const ImageContainer = styled('figure', {
  order: 0,
  maxWidth: '327px',
  maxHeight: '290px',
  flexShrink: 0,

  '@bp2': {
    order: 2,
    width: '505px',
    height: '448px',
    maxWidth: 'initial',
    maxHeight: 'initial',
  },
})
