import { styled } from 'stitches.config'
import { Text } from 'components/Text'
import { VerticalSeparator } from 'components/VerticalSeparator'
import { BulletedListRoot } from 'components/BulletedList/BulletedList.styles'

export const PatternBenefits = styled('img', {
  display: 'none',

  '@bp2': {
    display: 'block',
    height: '480px',
    position: 'absolute',
    transform: 'translateY(-$space$1)',
    top: '-$12',
  },
})

export const Wrapper = styled('div', {
  paddingTop: '$2',

  '@bp2': {
    paddingTop: 0,
  },
})

export const ClaimVerticalSeparator = styled(VerticalSeparator, {
  display: 'none',

  '@bp2': {
    display: 'block',
    minHeight: '214px',
  },
})

export const HomeRoot = styled('div', {
  backgroundColor: '$secondary-100',
  width: '100%',
  '& img': {
    '@bppsd': {
      display: 'none !important',
    },
    '@bppst': {
      display: 'none !important',
    },
    '@bppsm': {
      display: 'none !important',
    },
  },
})
