import React, { ComponentProps } from 'react'
import { useTranslation } from 'hooks/useTranslation'
import { Button } from 'components/Button'
import { Icon } from 'components/Icon'
import { Link } from 'components/Link'
import { Stack } from 'components/Stack'
import { Text } from 'components/Text'
import { EndingRoot, SecondaryCTA, ScrollTopButton } from './Ending.styles'

type EndingOwnProps = ComponentProps<typeof EndingRoot>
export type EndingProps = EndingOwnProps & {}

export const Ending = (_props: EndingProps) => {
  const { t } = useTranslation()

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  return (
    <EndingRoot>
      <Stack direction="column" gap={{ '@initial': '5', '@bp2': '0' }}>
        <Stack direction="column" gap={{ '@initial': '2', '@bp1': '3' }}>
          <Text size={{ '@initial': 'h3', '@bp1': 'h2' }} as="h2" weight="medium" align="center">
            {t('home:ending.title')}
          </Text>
          <Stack direction="column" alignItems="center" gap={{ '@initial': '6', '@bp1': '5' }}>
            <Text size="headline" align="center" css={{ maxWidth: '560px' }}>
              {t('home:ending.main')}
            </Text>
            <Stack direction="column" gap={3} css={{ alignItems: 'center' }}>
              <Link href="/meal-plan" passHref>
                <Button as="a" className="cta-footer">
                  {t('home:ending.CTA')}
                </Button>
              </Link>
              <SecondaryCTA>
                <span>{t('home:ending.or')}</span>{' '}
                <Link href="/products">{t('home:ending.secondaryCTA')}</Link>
              </SecondaryCTA>
            </Stack>
          </Stack>
        </Stack>
        <ScrollTopButton onClick={scrollToTop} aria-label={t('home:ending.scrollTop')}>
          <Icon name="arrow-circle-up"></Icon>
        </ScrollTopButton>
      </Stack>
    </EndingRoot>
  )
}

Ending.displayName = 'Ending'
