import { styled } from 'stitches.config'

export const Title = styled('div', {
  marginBottom: '$2',

  '& span': {
    display: 'block',
  },

  '@bp2': {
    marginBottom: '$3',
  },

  '@bp3': {
    marginBottom: '$3',

    '& span': {
      whiteSpace: 'nowrap',
    },
  },
})

export const Subtitle = styled('p', {
  fontWeight: '$regular',
  fontSize: '$headline',
  lineHeight: '$headline',
  whiteSpace: 'wrap',

  '& span': {
    display: 'block',
  },

  '@bp1': {
    whiteSpace: 'nowrap',
  },

  '@bp2': {
    marginBottom: '$4',
  },
})

export const HeroText = styled('div', {
  position: 'relative',
  zIndex: 1,
  textAlign: 'center',

  '& a': {
    margin: '0 !important',
  },

  '@bp2': {
    maxWidth: '50%',
    textAlign: 'left',
  },
})

export const HeroImgWrapper = styled('div', {
  position: 'relative',
  width: '327px',

  '@bp1': {
    width: '477px',
  },
})

export const HeroImgDesktopContainer = styled('div', {
  display: 'none',

  '@bp3': {
    position: 'absolute',
    top: '200px',
    display: 'block',
    width: '280px',
    right: '470px',
  },
})

export const HeroImages = styled('div', {
  position: 'relative',
  display: 'flex',
  width: 'calc(100vw - $6)',
  justifyContent: 'center',

  '@bp3': {
    justifyContent: 'flex-end',
  },
})

export const IsologoContainer = styled('div', {
  position: 'absolute',
  top: '-15px',
  left: '-15px',
})

export const HeroContent = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  maxWidth: '$max',
  width: '100%',
  gap: '$5',
  margin: 'auto',

  '@bp2': {
    px: '$9',
    flexDirection: 'row',
    alignItems: 'flex-start',
    paddingTop: '$14',
    paddingBottom: '$9',
    gap: 0,
  },

  '@bp3': {
    px: '$9',
  },
})

export const HeroRoot = styled('div', {
  color: '$primary-800',
  backgroundColor: '$secondary-100',
  background: 'url(assets/pattern-home-mobile-01.png)',
  backgroundSize: '120px',
  backgroundPosition: 'right 90%',
  backgroundRepeat: 'no-repeat',
  paddingTop: '$3',

  '@mobile': {
    backgroundPosition: 'right 70%',
  },

  '@bp1': {
    background: 'url(assets/pattern-home-desktop-01.png)',
    backgroundSize: '210px',
    backgroundPosition: 'right',
    backgroundRepeat: 'no-repeat',
  },

  '@bp2': {
    paddingTop: 0,
  },

  '@bppsd': {
    background: 'none',
  },

  '@bppst': {
    background: 'none',
  },
  '@bppsm': {
    background: 'none',
  },

  '.cta': {
    display: 'none',

    '@bp2': {
      cursor: 'pointer',
      display: 'inline-flex',
      marginTop: '$2',
    },
  },
})
