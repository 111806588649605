export const isElementVisible = (querySelector: string) => {
  const rect = document.querySelector(querySelector)?.getBoundingClientRect()

  if (!rect) return

  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  )
}
