import React, { ComponentProps } from 'react'
import Image from 'next/image'
import { Button } from 'components/Button'
import { Link } from 'components/Link'
import { Text } from 'components/Text'
import { useTranslation } from 'hooks/useTranslation'
import {
  HeroRoot,
  HeroText,
  Title,
  Subtitle,
  HeroImages,
  HeroImgWrapper,
  IsologoContainer,
  HeroImgDesktopContainer,
  HeroContent,
} from './Hero.styles'

import heroDesktop from '../../../../../public/assets/hero-desktop.png'
import hero from '../../../../../public/assets/hero.png'
import isologo from '../../../../../public/assets/isologo.svg'

type HeroOwnProps = ComponentProps<typeof HeroRoot>
export type HeroProps = HeroOwnProps & {}

export const Hero = (props: HeroProps) => {
  const { t } = useTranslation()

  return (
    <HeroRoot {...props}>
      <HeroContent>
        <HeroText>
          <Title>
            <Text size={{ '@initial': 'h3', '@bp2': 'h2', '@bp3': 'h1' }} as="h1" weight="bold">
              <span>{t('home:hero.title1')}</span>
              <span>{t('home:hero.title2')}</span>
            </Text>
          </Title>

          <Subtitle as="p">
            <span>{t('home:hero.subtitle1')}</span>
            <span>{t('home:hero.subtitle2')}</span>
            <span>{t('home:hero.subtitle3')}</span>
            <span>{t('home:hero.subtitle4')}</span>
          </Subtitle>
          <Link href="/meal-plan" passHref>
            <Button as="a" className="cta">
              {t('home:hero.CTA')}
            </Button>
          </Link>
        </HeroText>
        <HeroImages role="presentation">
          <HeroImgDesktopContainer>
            <Image src={heroDesktop} alt={t('home:hero.image2')} loading="eager" />
          </HeroImgDesktopContainer>
          <HeroImgWrapper>
            <Image src={hero} alt={t('home:hero.image1')} loading="eager" />
            <IsologoContainer>
              <Image src={isologo} alt="" width={120} height={120} loading="eager" />
            </IsologoContainer>
          </HeroImgWrapper>
        </HeroImages>
      </HeroContent>
    </HeroRoot>
  )
}

Hero.displayName = 'Hero'
